import React from "react";
import styles from "./Header.module.css";
import logo from '../../assets/logo.svg';
import { Layout, Typography, Input, Menu, Button, Dropdown } from "antd";
import { GlobalOutlined } from "@ant-design/icons"
import { useHistory, useLocation, useRouteMatch, useParams } from "react-router-dom"
import { useSelector } from "../../redux/hooks"
import { useDispatch } from "react-redux"
import { changeLanguageActionCreator, addLanguageActionCreator } from "../../redux/language/languageActons"
import { useTranslation } from "react-i18next"

export const Header: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const params = useParams()
  const language = useSelector((state) => state.language.language);
  const languageList = useSelector((state) => state.language.languageList);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const menuClickHandler = (e) => {
    console.log(e);
    if (e.key === "new") {
      // 处理新语言添加action
      dispatch(addLanguageActionCreator("新语言", "new_lang"))
    } else {
      dispatch(changeLanguageActionCreator(e.key))
    }
  };

  return <div className={styles['app-header']}>
    {/* top-header */}
    <div className={styles['top-header']}>
      <div className={styles.inner}>
        <Typography.Text>{t("header.slogan")}</Typography.Text>
        <Dropdown.Button
          style={{ marginLeft: 15 }}
          overlay={
            <Menu onClick={menuClickHandler}>
              {languageList.map((l) => {
                return <Menu.Item key={l.code}>{l.name}</Menu.Item>;
              })}
              <Menu.Item key={"new"}>
                {t("header.add_new_language")}
              </Menu.Item>
            </Menu>
          }
          icon={<GlobalOutlined />}
        >
          {language === "zh" ? "中文" : "English"}
        </Dropdown.Button>
        <Button.Group className={styles['button-group']}>
          <Button onClick={() => history.push("register")}>{t("header.register")}</Button>
          <Button onClick={() => history.push("signIn")}>{t("header.signin")}</Button>
        </Button.Group>
      </div>
    </div>
    <Layout.Header className={styles["main-header"]}>
      <span onClick={() => history.push("/")}>
        <img src={logo} alt="logo" className={styles["App-logo"]} />
        <Typography.Title level={3} className={styles.title}>
          {t("header.title")}
        </Typography.Title>
      </span>
      <Input.Search
        placeholder={"请输入装修主题或关键字"}
        className={styles["search-input"]}
      />
    </Layout.Header>
    <Menu mode={"horizontal"} className={styles["main-menu"]}>
      <Menu.Item key="1"> {t("header.home_page")} </Menu.Item>
      <Menu.Item key="2"> {t("header.bbs")} </Menu.Item>
      <Menu.Item key="3"> {t("header.gangmaster")} </Menu.Item>
      <Menu.Item key="4"> {t("header.materials")} </Menu.Item>
      <Menu.Item key="5"> {t("header.purchase")} </Menu.Item>
      <Menu.Item key="6"> {t("header.cruise")} </Menu.Item>
    </Menu>
  </div>;
};