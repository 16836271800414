// 用户评论
export const commentMockData = [
  {
    author: "Han Solo",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    content: `前往码头搭乘快艇前往潜水艇平台，搭乘潜水艇潜入7米深红海，欣赏神秘美丽的红海海底世界。并有潜水员为您献上海底喂鱼的表演。含接送，中文导游陪同`,
    createDate: "2020-01-05",
  },
  {
    author: "Han Solo",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    content: `早上5点左右从酒店出发前往码头，搭乘小船前往尼罗河西岸。小船上准备有热咖啡，茶，以及小点心。搭乘热气球迎接日出，并俯瞰卢克索。途径哈布城，女王神庙，帝王谷等景点，从高空体验不一样的卢克索。落地后会举办一个小的庆祝仪式，并为客人颁发证书。之后专车送返酒店。`,
    createDate: "2020-02-06",
  },
  {
    author: "Han Solo",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    content: `行程安排仅供参考，视当天具体天气情况而定。若因天气状况而无法乘坐热气球，则原价退还！`,
    createDate: "2020-01-04",
  },
  {
    author: "Han Solo",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    content: `搭乘特殊定制的游船，在游船底部设有大型观光窗，便于欣赏奇妙的海底世界。包含接送，中文导游陪同注意：上下船时请注意安全，依次排队切勿拥挤、小心地滑。70美金/人（4人起）`,
    createDate: "2020-02-05",
  },
];
