import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import axios from "axios";
import fetchJsonp from 'fetch-jsonp';

export const FETCH_RECOMMEND_PRODUCTS_START =
  "FETCH_RECOMMEND_PRODUCTS_START"; // 正在调用推荐信息api
export const FETCH_RECOMMEND_PRODUCTS_SUCCESS =
  "FETCH_RECOMMEND_PRODUCTS_SUCCESS"; // 推荐信息api调用成功
export const FETCH_RECOMMEND_PRODUCTS_FAIL =
  "FETCH_RECOMMEND_PRODUCTS_FAIL"; // 推荐信息api调用失败

interface FetchRecommendProductStartAction {
  type: typeof FETCH_RECOMMEND_PRODUCTS_START
}

interface FetchRecommendProductSuccessAction {
  type: typeof FETCH_RECOMMEND_PRODUCTS_SUCCESS,
  payload: any,
}

interface FetchRecommendProductFailAction {
  type: typeof FETCH_RECOMMEND_PRODUCTS_FAIL,
  payload: any
}

export type RecommendProductAction =
  | FetchRecommendProductStartAction
  | FetchRecommendProductSuccessAction
  | FetchRecommendProductFailAction;

export const fetchRecommendProductStartActionCreator = (): FetchRecommendProductStartAction => {
  return {
    type: FETCH_RECOMMEND_PRODUCTS_START,
  };
};

export const fetchRecommendProductSuccessActionCreator = (data): FetchRecommendProductSuccessAction => {
  return {
    type: FETCH_RECOMMEND_PRODUCTS_SUCCESS,
    payload: data
  }
}

export const fetchRecommendProductFailActionCreator = (error): FetchRecommendProductFailAction => {
  return {
    type: FETCH_RECOMMEND_PRODUCTS_FAIL,
    payload: error
  }
}


// thunk 可以返回一个函数，而不一定是js对象
// 在一个thunk action中可以完成一些列连续的action操作
// 并且可以处理异步逻辑
// 业务逻辑可以从ui层面挪到这里，代码分层会更清晰
export const giveMeDataActionCreator = (): ThunkAction<
  void,
  RootState,
  unknown,
  RecommendProductAction
> => async (dispatch, getState) => {
  dispatch(fetchRecommendProductStartActionCreator());
  try {
    console.log("zuola-productCollections:", "start");
    const kkapi = 'https://api.zhuangxiaoer.cn/client/zhuangxiaoer/api/productCollections'
    fetchJsonp(kkapi)
      .then(function (response) {
        return response.json()
      }).then((json) => {
        console.log(json);
        console.log("zuola-datadata:", JSON.stringify(json));
        const kdata = json.data
        console.log("zuola-productCollections-data:", JSON.stringify(kdata));
        dispatch(fetchRecommendProductSuccessActionCreator(kdata));
      }).catch(function (ex) {
        dispatch(fetchRecommendProductSuccessActionCreator(null));
      })
    // const { data } = await axios.get(
    //   "http://api.zhuangxiaoer.cn/client/zhuangxiaoer/api/productCollections"
    // );
    // console.log("zuola-datadata:", JSON.stringify(data));
    // const kdata = data.data
    // console.log("zuola-productCollections-data:", JSON.stringify(kdata));
    // dispatch(fetchRecommendProductSuccessActionCreator(kdata));
  } catch (error) {
    console.log("zuola-productCollections-error:", error.message);
    dispatch(fetchRecommendProductSuccessActionCreator(null));
    // dispatch(fetchRecommendProductFailActionCreator(error.message));
  }
};