import { FETCH_RECOMMEND_PRODUCTS_FAIL, FETCH_RECOMMEND_PRODUCTS_SUCCESS, FETCH_RECOMMEND_PRODUCTS_START, RecommendProductAction } from "./recommendProductsActions"


interface recommendProductsState {
  productList: any[],
  loading: boolean,
  error: string | null
}

const defaltState: recommendProductsState = {
  loading: true,
  error: null,
  productList: []
}

export default (state = defaltState, action: RecommendProductAction) => {
  switch (action.type) {
    case FETCH_RECOMMEND_PRODUCTS_START:
      return { ...state, loading: true }
    case FETCH_RECOMMEND_PRODUCTS_SUCCESS:
      return { ...state, loading: false, productList: action.payload }
    case FETCH_RECOMMEND_PRODUCTS_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state;
  }
}